import React, { FC } from 'react';
import classNames from 'classnames';

import { Container } from 'layout';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import Button from 'common/Button';
import useScreenRecognition from 'hooks/useScreenRecognition';
import GatsbyImage from 'common/GatsbyImage';

import { IPropsHeroBanner } from './models';
import './HeroBanner.scss';
import './ShadowHeroBanner.scss';

const HeroBanner: FC<IPropsHeroBanner> = ({
  heroBanner,
  hideOnMobile,
  isHomePage = false,
  isLazyLoading = false,
  showTitleOnTop = false,
}) => {
  const {
    properties: {
      image,
      mobileImage,
      imageS,
      mobileImageS,
      title,
      buttons,
      subTitle,
      imagePluses,
      mobileImagePluses,
      imageSAltLabel,
      heroBannerImageAltText,
      imagePlusesAltLabel,
      customClassName,
      showTitleMobile
    },
  } = heroBanner[0];

  const { isDesktop } = useScreenRecognition();

  const shouldRenderTitle = !hideOnMobile || isDesktop;


  return (
    <div
      data-test="HeroBanner"
      className={classNames(`hero-banner__wrapper`, {
        'hero-banner--index': isHomePage,
        [`hero-banner--${customClassName}`]: customClassName,
      })}
    >
      {(showTitleMobile || showTitleOnTop) && !isDesktop ? (
        <Container className="hero-banner-content-container">
          <div className="hero-banner__content">
            <DangerouslySetInnerHtml html={title} className="content__title mobile_view_title" />
          </div>
        </Container>
      ) : null}

      <div className="hero-banner__image-box">
        <GatsbyImage
          alt={imageSAltLabel}
          image={{ ...imageS, mobileImage: mobileImageS?.gatsbyImage }}
          className="hero-banner__image-s"
          isLazyLoading={isLazyLoading}
          fadeIn={false}
        />
        <GatsbyImage
          alt={heroBannerImageAltText}
          image={{ ...image, mobileImage: mobileImage?.gatsbyImage }}
          className="hero-banner__image"
          isLazyLoading={isLazyLoading}
          fadeIn={false}
          useFocalPoint
        />
        {imagePluses ? (
          <Container>
            <GatsbyImage
              alt={imagePlusesAltLabel}
              image={{ ...imagePluses, mobileImage: mobileImagePluses?.gatsbyImage }}
              className="hero-banner__image-pluses"
              isLazyLoading={isLazyLoading}
              fadeIn={false}
            />
          </Container>
        ) : null}
      </div>
      <Container className="hero-banner-content-container">
        {buttons?.length || subTitle || isDesktop ? (
          <div className="hero-banner__content">
            {shouldRenderTitle ? (
              <DangerouslySetInnerHtml html={title} className="content__title" />
            ) : <DangerouslySetInnerHtml html={title} className="content__title hideOnMobile" />}

            {subTitle ? (
              <div className="content__sub-title">
                {isDesktop ? <span className="content__sub-title--line" /> : null}
                <DangerouslySetInnerHtml html={subTitle} />
              </div>
            ) : null}

            {buttons ? (
              <div className="content__buttons-block">
                {buttons.map(({ properties: { ariaLabel, link, label } }) => (
                  <Button
                    key={label}
                    className="content__button"
                    link={link?.[0]?.url}
                    ariaLabel={ariaLabel}
                  >
                    {label}
                  </Button>
                ))}
              </div>
            ) : null}
          </div>
        ) : null}
      </Container>
    </div>
  );
};
export default HeroBanner;
